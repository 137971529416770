import React, { useState, useEffect } from "react"
import { MaxWidth, Text, Stack } from "@sqymagma/elements"
import Layout from "../components/Layout"
import TrendsViewer from "../components/TrendsViewer"
import useTrends from "../hooks/useTrends"
import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"
import Line from "../components/Line"
import HeroContainer from "../components/HeroContainer"
import { trendsPage } from "../../data"

const TrendsPage = () => {
  const trendsData = useTrends()
  const breakpoints = useBreakpoint()

  const [numOfCols, setNumOfCols] = useState()

  useEffect(() => {
    setNumOfCols(breakpoints.s && !breakpoints.l ? 2 : 3)
  }, [breakpoints.s, breakpoints.l])

  return (
    <Layout title={"Trends 2020"} seoTitle={"Trends 2020"} footerSimple={true}>
      <HeroContainer
        as="section"
        height="auto"
        minHeight={{ default: "360px", m: "480px", l: "600px" }}
        withDecoration
      >
        <Stack
          width={{ default: 1, m: 9 / 12, l: 8 / 12 }}
          vs="m"
          flexDirection="column"
          py={{ default: "xl", m: "4xl" }}
          maxWidth="900px"
        >
          <Text as="h1" textStyle="display02" mb="0">
            {trendsPage.title}
          </Text>
          <Text as="h2" textStyle="title04" mb="0">
            {trendsPage.description}
          </Text>
        </Stack>
        <Line bleeding={"true"} />
      </HeroContainer>
      <MaxWidth>
        <TrendsViewer
          cols={numOfCols}
          content={trendsData}
          py={{ default: "m", xxl: "xl" }}
          mb="xxl"
        ></TrendsViewer>
      </MaxWidth>
    </Layout>
  )
}

export default TrendsPage
